import React, { useState } from "react";
import { Menu, Dropdown, Table, Switch, Tooltip } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
import { ColumnsType } from "antd/lib/table";

import AvatarGroup from "../../AvatarGroup/AvatarGroup.component";
import {
  LoadingIcon,
  MoreActionsIcon,
} from "../../CustomIcons/CustomIcons.component";
import Modal from "../../General/Modal/Modal";
import MembersTable from "../MembersTable/MembersTable.component";
import MembersSearchBar from "../MembersSearchBar/MembersSearchBar.component";
import { selectUserDetailsState } from "../../../reducers/user-details.reducer";
import { getInitials } from "../../../utils/string.util";

const TaskListTable: React.FC<TaskListTableProps> = ({
  projectId,
  loading,
  type,
  data,
  projectMembers,
  accessType,
  onRename,
  onArchive,
  onAddMember,
  onDeleteMember,
  onSwitchToggle,
}) => {
  const userDetails = useSelector(selectUserDetailsState).data;
  const [openListMembers, setOpenListMembers] = useState(false);
  const [selectedListId, setSelectedListId] = useState("");
  const [toggling, setToggling] = useState<{ id: string; loading: boolean }>();

  const selectedList = data.find((item) => item.id === selectedListId) || {
    members: [],
  };
  const selectedMembers = selectedList.members;
  const searchableMembers = projectMembers.filter(
    (user) => !selectedMembers.find((item: any) => item.id === user.id)
  );

  const handleAvatarsClick = (listId: string) => {
    setOpenListMembers(true);
    setSelectedListId(listId);
  };

  const handleRename = (list: ListObject) => {
    onRename(list.id);
  };

  const handleArchive = (list: ListObject, archived: boolean) => {
    onArchive(list.id, archived);
  };

  const handleUserDelete = async (user: ProjectUser) => {
    await onDeleteMember(user.membershipId);
  };

  const handleAddUserToList = async (userId: string) => {
    await onAddMember(userId, selectedListId);
  };

  const handleSwitch = async (
    listId: string,
    membershipId: string,
    checked: boolean
  ) => {
    setToggling({ id: membershipId, loading: true });
    await onSwitchToggle(listId, membershipId, checked);
    setToggling({ id: membershipId, loading: false });
  };

  const menu = (list: ListObject) => (
    <Menu className="ProjectOverview__Menu">
      <Menu.Item onClick={() => handleRename(list)}>Rename</Menu.Item>
      {type === "active" && (
        <Menu.Item onClick={() => handleArchive(list, true)}>Archive</Menu.Item>
      )}
      {type === "archived" && (
        <Menu.Item onClick={() => handleArchive(list, false)}>
          Unarchive
        </Menu.Item>
      )}
    </Menu>
  );
  let columns: ColumnsType<any> | undefined = [
    {
      title: "Name",
      dataIndex: "title",
      key: "name",
    },
    {
      title: "Tasks",
      dataIndex: "tasks",
      key: "tasks",
      align: "right",
    },
    {
      title: "Show in sidebar",
      dataIndex: "show_list_in_sidebar_yn",
      key: "sidebar",
      render: (showInSidebar: boolean, data: any) => {
        const current = data.members.find(
          (member: ListUser) => member.id === userDetails.id
        );
        return (
          <Tooltip
            title={current ? undefined : "You need to be a member of this list"}
            getPopupContainer={(trigger: any) => trigger}
          >
            <Switch
              disabled={!current}
              loading={
                toggling &&
                current &&
                toggling.id === current.membershipId &&
                toggling.loading
              }
              checked={showInSidebar}
              onChange={() =>
                handleSwitch(data.id, current.membershipId, !showInSidebar)
              }
            />
          </Tooltip>
        );
      },
    },
    {
      title: "Unread activities",
      dataIndex: "unread_activity_count",
      key: "unread_activity_count",
      align: "right",
    },
    {
      title: "Last active",
      dataIndex: "lastActive",
      key: "lastActive",
      align: "right",
      render: (date: string) =>
        date ? moment(date).format("YYYY-MM-DD hh:mm") : "",
    },
    {
      title: "Members",
      dataIndex: "members",
      key: "members",
      render: (users: ListUser[], data: any) => {
        const owners = users.filter((user) => user.accessType === "owner");
        const members = users.filter((user) => user.accessType === "member");
        owners.sort((a, b) => a.displayName.localeCompare(b.displayName));
        members.sort((a, b) => a.displayName.localeCompare(b.displayName));
        const newMembers = [...owners, ...members];
        return (
          <AvatarGroup
            avatars={newMembers.map((member) => member.avatar)}
            avatarsFallBackText={newMembers.map((member) =>
              getInitials(member.displayName)
            )}
            onClick={() => handleAvatarsClick(data.id)}
          />
        );
      },
    },
  ];
  columns =
    accessType === "owner"
      ? [
          ...columns,
          {
            title: "",
            key: "action",
            render: (list: ListObject) => {
              return (
                <Dropdown
                  overlay={menu(list)}
                  placement="bottomLeft"
                  trigger={["click"]}
                >
                  <MoreActionsIcon style={{ color: "#0000007F" }} />
                </Dropdown>
              );
            },
          } as any,
        ]
      : columns;

  return !loading && data.length === 0 ? (
    <div className="TaskListTable__Empty">
      {type === "active"
        ? "There are no task lists yet."
        : "There are no archived task lists yet."}
    </div>
  ) : (
    <>
      <Table
        className="ProjectOverview__Table TaskListTable"
        loading={{
          spinning: loading,
          indicator: <LoadingIcon />,
        }}
        columns={columns}
        dataSource={data.sort((a, b) => a.title.localeCompare(b.title))}
        pagination={false}
      />

      <Modal
        title={`${selectedList.title}'s list members`}
        width={976}
        visible={openListMembers}
        maskClosable={false}
        onOk={() => setOpenListMembers(false)}
        okText="Done"
        onCancel={() => setOpenListMembers(false)}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        {accessType === "owner" && (
          <MembersSearchBar
            popoverClassName="TaskListTable__SearchBar"
            members={searchableMembers}
            type="list"
            onMemberAdd={handleAddUserToList}
          />
        )}
        <MembersTable
          projectId={projectId}
          type="taskList"
          title={selectedList.title}
          accessType={accessType}
          data={selectedMembers}
          onSave={() => {}}
          onDelete={handleUserDelete}
        />
      </Modal>
    </>
  );
};

export default TaskListTable;
